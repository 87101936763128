export const AvatarComponent = () => import('../../../src/components/AvatarComponent.vue' /* webpackChunkName: "components/avatar-component" */).then(c => wrapFunctional(c.default || c))
export const BaseAccordion = () => import('../../../src/components/BaseAccordion.vue' /* webpackChunkName: "components/base-accordion" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../../src/components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../../src/components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../../src/components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../../src/components/LineChart.js' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../../src/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoHeart = () => import('../../../src/components/LogoHeart.vue' /* webpackChunkName: "components/logo-heart" */).then(c => wrapFunctional(c.default || c))
export const PhotoBox = () => import('../../../src/components/PhotoBox.vue' /* webpackChunkName: "components/photo-box" */).then(c => wrapFunctional(c.default || c))
export const ProfileSideNav = () => import('../../../src/components/ProfileSideNav.vue' /* webpackChunkName: "components/profile-side-nav" */).then(c => wrapFunctional(c.default || c))
export const RichEditor = () => import('../../../src/components/RichEditor.vue' /* webpackChunkName: "components/rich-editor" */).then(c => wrapFunctional(c.default || c))
export const SlideOut = () => import('../../../src/components/SlideOut.vue' /* webpackChunkName: "components/slide-out" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionCard = () => import('../../../src/components/SubscriptionCard.vue' /* webpackChunkName: "components/subscription-card" */).then(c => wrapFunctional(c.default || c))
export const TitleDescription = () => import('../../../src/components/TitleDescription.vue' /* webpackChunkName: "components/title-description" */).then(c => wrapFunctional(c.default || c))
export const AdminAccessCode = () => import('../../../src/components/admin/AccessCode.vue' /* webpackChunkName: "components/admin-access-code" */).then(c => wrapFunctional(c.default || c))
export const AdminAddProgramType = () => import('../../../src/components/admin/AddProgramType.vue' /* webpackChunkName: "components/admin-add-program-type" */).then(c => wrapFunctional(c.default || c))
export const AdminProfile = () => import('../../../src/components/admin/AdminProfile.vue' /* webpackChunkName: "components/admin-profile" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowNext = () => import('../../../src/components/icons/ArrowNext.vue' /* webpackChunkName: "components/icons-arrow-next" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowPrev = () => import('../../../src/components/icons/ArrowPrev.vue' /* webpackChunkName: "components/icons-arrow-prev" */).then(c => wrapFunctional(c.default || c))
export const IconsMuscleFigure = () => import('../../../src/components/icons/MuscleFigure.vue' /* webpackChunkName: "components/icons-muscle-figure" */).then(c => wrapFunctional(c.default || c))
export const IconsTimer = () => import('../../../src/components/icons/Timer.vue' /* webpackChunkName: "components/icons-timer" */).then(c => wrapFunctional(c.default || c))
export const NotificationBellButton = () => import('../../../src/components/notification/BellButton.vue' /* webpackChunkName: "components/notification-bell-button" */).then(c => wrapFunctional(c.default || c))
export const VuetifyDatePickField = () => import('../../../src/components/vuetify/DatePickField.vue' /* webpackChunkName: "components/vuetify-date-pick-field" */).then(c => wrapFunctional(c.default || c))
export const VuetifyDropDownMenuBtn = () => import('../../../src/components/vuetify/DropDownMenuBtn.vue' /* webpackChunkName: "components/vuetify-drop-down-menu-btn" */).then(c => wrapFunctional(c.default || c))
export const StripeCardOnFile = () => import('../../../src/components/stripe/CardOnFile.vue' /* webpackChunkName: "components/stripe-card-on-file" */).then(c => wrapFunctional(c.default || c))
export const StripeCheckout = () => import('../../../src/components/stripe/Checkout.vue' /* webpackChunkName: "components/stripe-checkout" */).then(c => wrapFunctional(c.default || c))
export const StripeProductsList = () => import('../../../src/components/stripe/ProductsList.vue' /* webpackChunkName: "components/stripe-products-list" */).then(c => wrapFunctional(c.default || c))
export const ProfileCheckinsAddCheckin = () => import('../../../src/components/profile/checkins/AddCheckin.vue' /* webpackChunkName: "components/profile-checkins-add-checkin" */).then(c => wrapFunctional(c.default || c))
export const ProfileCheckinsCheckIn = () => import('../../../src/components/profile/checkins/CheckIn.vue' /* webpackChunkName: "components/profile-checkins-check-in" */).then(c => wrapFunctional(c.default || c))
export const ProfileCheckinsCheckInMobile = () => import('../../../src/components/profile/checkins/CheckInMobile.vue' /* webpackChunkName: "components/profile-checkins-check-in-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProfileCheckinsThirdWeekCompleted = () => import('../../../src/components/profile/checkins/ThirdWeekCompleted.vue' /* webpackChunkName: "components/profile-checkins-third-week-completed" */).then(c => wrapFunctional(c.default || c))
export const ProfileCheckinsCalculateMetricsUtil = () => import('../../../src/components/profile/checkins/calculateMetricsUtil.js' /* webpackChunkName: "components/profile-checkins-calculate-metrics-util" */).then(c => wrapFunctional(c.default || c))
export const ProfileBeforeAfter = () => import('../../../src/components/profile/before-after/BeforeAfter.vue' /* webpackChunkName: "components/profile-before-after" */).then(c => wrapFunctional(c.default || c))
export const ProfileBeforeAfterUpdateBeforeAfterImg = () => import('../../../src/components/profile/before-after/updateBeforeAfterImg.js' /* webpackChunkName: "components/profile-before-after-update-before-after-img" */).then(c => wrapFunctional(c.default || c))
export const ProfileFeedCommentsFeed = () => import('../../../src/components/profile/feed/CommentsFeed.vue' /* webpackChunkName: "components/profile-feed-comments-feed" */).then(c => wrapFunctional(c.default || c))
export const ProfileFeedCommentsForm = () => import('../../../src/components/profile/feed/CommentsForm.vue' /* webpackChunkName: "components/profile-feed-comments-form" */).then(c => wrapFunctional(c.default || c))
export const ProfileFeedCreatePost = () => import('../../../src/components/profile/feed/CreatePost.vue' /* webpackChunkName: "components/profile-feed-create-post" */).then(c => wrapFunctional(c.default || c))
export const ProfileFeed = () => import('../../../src/components/profile/feed/Feed.vue' /* webpackChunkName: "components/profile-feed" */).then(c => wrapFunctional(c.default || c))
export const ProfileFeedPostCard = () => import('../../../src/components/profile/feed/PostCard.vue' /* webpackChunkName: "components/profile-feed-post-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileFeedPostSettings = () => import('../../../src/components/profile/feed/PostSettings.vue' /* webpackChunkName: "components/profile-feed-post-settings" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramAddDayNote = () => import('../../../src/components/profile/fitness-program/AddDayNote.vue' /* webpackChunkName: "components/profile-fitness-program-add-day-note" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramAddExercise = () => import('../../../src/components/profile/fitness-program/AddExercise.vue' /* webpackChunkName: "components/profile-fitness-program-add-exercise" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramAddToProgramForm = () => import('../../../src/components/profile/fitness-program/AddToProgramForm.vue' /* webpackChunkName: "components/profile-fitness-program-add-to-program-form" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramCompleteWorkout = () => import('../../../src/components/profile/fitness-program/CompleteWorkout.vue' /* webpackChunkName: "components/profile-fitness-program-complete-workout" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramCountDownTimer = () => import('../../../src/components/profile/fitness-program/CountDownTimer.vue' /* webpackChunkName: "components/profile-fitness-program-count-down-timer" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramDeleteWeek = () => import('../../../src/components/profile/fitness-program/DeleteWeek.vue' /* webpackChunkName: "components/profile-fitness-program-delete-week" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramEditDayCard = () => import('../../../src/components/profile/fitness-program/EditDayCard.vue' /* webpackChunkName: "components/profile-fitness-program-edit-day-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramExerciseCard = () => import('../../../src/components/profile/fitness-program/ExerciseCard.vue' /* webpackChunkName: "components/profile-fitness-program-exercise-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramExerciseInstructions = () => import('../../../src/components/profile/fitness-program/ExerciseInstructions.vue' /* webpackChunkName: "components/profile-fitness-program-exercise-instructions" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramExerciseVideoModal = () => import('../../../src/components/profile/fitness-program/ExerciseVideoModal.vue' /* webpackChunkName: "components/profile-fitness-program-exercise-video-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramExercisesByCat = () => import('../../../src/components/profile/fitness-program/ExercisesByCat.vue' /* webpackChunkName: "components/profile-fitness-program-exercises-by-cat" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgram = () => import('../../../src/components/profile/fitness-program/FitnessProgram.vue' /* webpackChunkName: "components/profile-fitness-program" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramMuscleGraph = () => import('../../../src/components/profile/fitness-program/MuscleGraph.vue' /* webpackChunkName: "components/profile-fitness-program-muscle-graph" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramShareAchievement = () => import('../../../src/components/profile/fitness-program/ShareAchievement.vue' /* webpackChunkName: "components/profile-fitness-program-share-achievement" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramUserPrograms = () => import('../../../src/components/profile/fitness-program/UserPrograms.vue' /* webpackChunkName: "components/profile-fitness-program-user-programs" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramWeekTabs = () => import('../../../src/components/profile/fitness-program/WeekTabs.vue' /* webpackChunkName: "components/profile-fitness-program-week-tabs" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramWorkoutNotes = () => import('../../../src/components/profile/fitness-program/WorkoutNotes.vue' /* webpackChunkName: "components/profile-fitness-program-workout-notes" */).then(c => wrapFunctional(c.default || c))
export const ProfileLevelOfFitness = () => import('../../../src/components/profile/level-of-fitness/LevelOfFitness.vue' /* webpackChunkName: "components/profile-level-of-fitness" */).then(c => wrapFunctional(c.default || c))
export const ProfileMeasurements = () => import('../../../src/components/profile/measurements/Measurements.vue' /* webpackChunkName: "components/profile-measurements" */).then(c => wrapFunctional(c.default || c))
export const ProfileNutritionsGroceries = () => import('../../../src/components/profile/nutritions/Groceries.vue' /* webpackChunkName: "components/profile-nutritions-groceries" */).then(c => wrapFunctional(c.default || c))
export const ProfileNutritions = () => import('../../../src/components/profile/nutritions/Nutritions.vue' /* webpackChunkName: "components/profile-nutritions" */).then(c => wrapFunctional(c.default || c))
export const ProfileNutritionsProgram = () => import('../../../src/components/profile/nutritions/NutritionsProgram.vue' /* webpackChunkName: "components/profile-nutritions-program" */).then(c => wrapFunctional(c.default || c))
export const ProfileNutritionsRecipes = () => import('../../../src/components/profile/nutritions/Recipes.vue' /* webpackChunkName: "components/profile-nutritions-recipes" */).then(c => wrapFunctional(c.default || c))
export const ProfilePersonalInformation = () => import('../../../src/components/profile/personal-information/PersonalInformation.vue' /* webpackChunkName: "components/profile-personal-information" */).then(c => wrapFunctional(c.default || c))
export const ProfilePersonalInformationUpdateInformationModal = () => import('../../../src/components/profile/personal-information/UpdateInformationModal.vue' /* webpackChunkName: "components/profile-personal-information-update-information-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileProgressCharts = () => import('../../../src/components/profile/progress/ProgressCharts.vue' /* webpackChunkName: "components/profile-progress-charts" */).then(c => wrapFunctional(c.default || c))
export const ProfileTrainerNotes = () => import('../../../src/components/profile/trainer-notes/TrainerNotes.vue' /* webpackChunkName: "components/profile-trainer-notes" */).then(c => wrapFunctional(c.default || c))
export const ProfileWorkoutLogAddExercise = () => import('../../../src/components/profile/workout-log/AddExercise.vue' /* webpackChunkName: "components/profile-workout-log-add-exercise" */).then(c => wrapFunctional(c.default || c))
export const ProfileWorkoutLogCreateEditWorkout = () => import('../../../src/components/profile/workout-log/CreateEditWorkout.vue' /* webpackChunkName: "components/profile-workout-log-create-edit-workout" */).then(c => wrapFunctional(c.default || c))
export const ProfileWorkoutLogWorkoutManual = () => import('../../../src/components/profile/workout-log/LogWorkoutManual.vue' /* webpackChunkName: "components/profile-workout-log-workout-manual" */).then(c => wrapFunctional(c.default || c))
export const ProfileWorkoutLogViewExercises = () => import('../../../src/components/profile/workout-log/ViewExercises.vue' /* webpackChunkName: "components/profile-workout-log-view-exercises" */).then(c => wrapFunctional(c.default || c))
export const ProfileWorkoutCard = () => import('../../../src/components/profile/workout-log/WorkoutCard.vue' /* webpackChunkName: "components/profile-workout-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileWorkoutLog = () => import('../../../src/components/profile/workout-log/WorkoutLog.vue' /* webpackChunkName: "components/profile-workout-log" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramProgramCardsOneTimeProgramCard = () => import('../../../src/components/profile/fitness-program/program-cards/OneTimeProgramCard.vue' /* webpackChunkName: "components/profile-fitness-program-program-cards-one-time-program-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileFitnessProgramProgramCardsOnlineProgramCard = () => import('../../../src/components/profile/fitness-program/program-cards/OnlineProgramCard.vue' /* webpackChunkName: "components/profile-fitness-program-program-cards-online-program-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
